<template>
  <div>
    <el-dialog title="Change Password" :visible.sync="dialogChangePassword" @submit.native.prevent="validate()" width="500px">
      <el-form ref="form" :model="form" label-position="left" :rules="rules" label-width="200px" status-icon>
        <el-form-item label="New Password" prop="password">
          <el-input v-model="form.password" type="password" placeholder="New Password"></el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password" prop="confirm_password">
          <el-input v-model="form.confirm_password" type="password" placeholder="Confirm New Password"></el-input>
        </el-form-item>
        <el-form-item>
          <button class="el-button el-button--primary">Change Password</button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-button type="primary" @click="dialogChangePassword = true">Change Password</el-button>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  data () {
    return {
      dialogChangePassword: false,
      form: {
        em_id: this.$store.getters.user.employeeId,
        password: null,
        confirm_password: null
      },
      rules: {
        password: [
          { required: true, message: 'New Password is required', trigger: 'blur' }
        ],
        confirm_password: [
          { required: true, message: 'Confirm New Password is required', trigger: 'blur' },
          { validator: this.comparePassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate((valid) => {
        this.dialogChangePassword = false
        this.submit()
      })
    },
    submit () {
      this.$http
        .post('ess.changepassword', this.form)
        .then(res => {
          this.$message({
            showClose: true,
            message: 'Password Changed',
            type: 'success'
          })
        })
    },
    comparePassword (rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('Password does not match!'))
      } else {
        callback()
      }
    }
  }
}
</script>
