<template>
  <el-row :gutter="20">
    <el-col :xs="24">
      <el-card>
        <div slot="header">
          <img class="el-image profile-photo" :src="photo" fit="cover" />
        </div>
        <div class="main-info">
          <h3>{{getFullName()}}</h3>
          <p>{{getPosition()}}</p>
          <p>{{getEmployeeId()}}</p>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24">
      <el-tabs v-model="activeName">
        <el-tab-pane label="Information" name="information" ><information :information="information"/></el-tab-pane>
        <!-- <el-tab-pane label="Notifications" name="notification" disabled><notification /></el-tab-pane> -->
        <el-tab-pane label="Documents" name="documents"><documents></documents></el-tab-pane>
        <el-tab-pane label="Settings" name="settings"><settings></settings></el-tab-pane>
        <!-- <el-tab-pane label="Help" name="help" disabled>Help</el-tab-pane> -->
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
import Information from './components/Information'
import Settings from './components/Settings'
import Documents from './components/Documents/index.vue'

export default {
  name: 'Profile',
  components: {
    Information,
    Settings,
    Documents
  },
  data () {
    return {
      activeName: 'information',
      employeeId: null,
      photo: this.$store.getters.user.photo,
      information: {
        profile: null,
        superiors: null,
        address: null
      }
    }
  },
  watch: {
    '$store.getters.user.photo': function (val) {
      this.photo = val
    }
  },
  mounted () {
    this.employeeId = this.$store.getters.user.employeeId
    this.getProfile()
  },
  methods: {
    getProfile () {
      this.$http
        .get(`ess.profile/${this.employeeId}`)
        .then(res => {
          this.information.profile = res.data.body
        })
      this.$http
        .get(`ess.superiors/${this.employeeId}`)
        .then(res => {
          this.information.superiors = res.data.body
        })
      this.$http
        .get(`ess.address/${this.employeeId}`)
        .then(res => {
          this.information.address = res.data.body
        })
    },
    getFullName () {
      return this.information.profile ? `${this.information.profile.firstname} ${this.information.profile.middlename} ${this.information.profile.lastname}` : ''
    },
    getPosition () {
      return this.information.profile ? this.information.profile.bp_id_name : ''
    },
    getEmployeeId () {
      return this.information.profile ? this.information.profile.empid : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  * {
    font-size: 14px !important;
  }
  .profile-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }
  .main-info {
    text-align: center;
    p {
      margin: .3rem;
    }
    h3 {
      margin: 0;
      font-style: italic bold;
    }
  }

  .el-tabs {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    >>> .el-tabs__nav-scroll {
      padding: 0 1rem;
      background-color: #fff;
    }
    >>> .el-tabs__header {
      margin: 0;
    }
    >>> .el-tabs__content {
      padding: 1rem;
      background-color: #fff;
    }
  }
</style>
