<template>
  <div>
    <!-- <el-link href="npa" target="_blank">Notice Of Personnel Action (NPA)</el-link> -->
    <el-dialog title="" :visible.sync="dialogNPAView" width="70%">
      <div v-if="document">
        <NPA :document="document" />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="2500"
            :filename="`NPA - ${document.employee_details.name}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="legal"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <NPA :document="document" />
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNPAView = false">Cancel</el-button>
        <el-button type="primary" @click="generateReport">Download</el-button>
        <el-tooltip v-if="!isAcknowledged" class="item" effect="dark" content="Acknowledge" placement="top-start">
          <el-button size="large" type="success" @click="confirmAcknowledge">Acknowledge</el-button>
        </el-tooltip>
      </span>
    </el-dialog>
    <el-dialog title="Acknowledge" :visible.sync="dialogAckVisible" :handle-confirm="acknowledge">
      <p>By clicking the 'I acknowledge' This means that you have read and understood the content of the document. In case you have clarifications or inquiries on the document details, you may send an email at petnet-compben@petnet.com.ph or reach out to Rhona Castillo via GoogleChat:rhona.castillo@petnet.com.ph. </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAckVisible = false">Cancel</el-button>
        <el-button type="primary" @click="acknowledge">Confirm</el-button>
      </span>
    </el-dialog>
    <el-select v-model="isAcknowledged" placeholder="Status">
      <el-option label="Acknowledged" :value="1"></el-option>
      <el-option label="Not Acknowledged" :value="0"></el-option>
    </el-select>
    <el-table :data="data" border>
      <el-table-column label="DOCUMENT" prop="document_name"></el-table-column>
      <el-table-column label="DESCRIPTION" prop="descriptions"></el-table-column>
      <el-table-column label="RELEASE DATE" prop="hr_release_date"></el-table-column>
      <el-table-column label="ACKNOWLEDGEMENT DATE" prop="em_id_acknowledgement_date"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" width="180">
        <template slot-scope="props">
          <div class="fixed-row">
            <el-tooltip class="item" effect="dark" content="View" placement="top-start">
              <el-button el-button size="mini" type="primary" @click="view(props.row)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="controls">
      <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div>
      <div class="control">
          <el-button-group>
            <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage" :disabled="!hasPreviousPage">Previous Page</el-button>
            <el-button type="primary" @click="nextPage" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
          </el-button-group>
      </div>
    </div>
  </div>
</template>
<script>
import NPA from '@/components/Documents/NPA.vue'
export default {
  components: {
    NPA
  },
  data () {
    return {
      dialogVisible: false,
      dialogAckVisible: false,
      dialogView: false,
      dialogNPAView: false,
      isAcknowledged: 0,
      id: null,
      documentData: {
        id: null,
        route: null
      },
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      data: [],
      form: {
        id: null
      }
    }
  },
  watch: {
    isAcknowledged () {
      this.getDocuments()
    },
    items () {
      this.getDocuments()
    }
  },
  mounted () {
    this.getDocuments()
  },
  methods: {
    getDocuments () {
      this.$http
        .get(`ess.hr.document-list/${this.$cookies.get('em_id')}/${this.isAcknowledged}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
        })
    },
    nextPage () {
      this.page++
      this.getDocuments()
    },
    prevPage () {
      this.page--
      this.getDocuments()
    },
    view (row) {
      this.$http
        .get(`hr.document-form-data/${row.id}/${row.bar_id}/${row.em_id}`)
        .then(res => {
          this.document = res.data.body
          if (this.document.bar_id === 1) {
            this.dialogNPAView = true
          } else if (this.document.bar_id === 2) {
            this.dialogMPPView = true
          }
        })
    },
    confirmAcknowledge () {
      this.dialogAckVisible = true
      this.form.id = this.document.employee_details.hed_id
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    acknowledge () {
      this.dialogAckVisible = false
      this.dialogNPAView = false
      this.$http
        .post('ess.hr.document/acknowledge', this.form)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              message: 'Document acknowledge',
              type: 'success'
            })
            this.getDocuments()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-select {
    float: right;
    margin-bottom: 1rem;
  }
</style>
