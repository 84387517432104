<template>
  <div v-if="information.profile">
    <el-row>
      <el-col :span="24">
        <h3>Employment Information</h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col :lg="4" :md="6" :xs="12" :sm="8">Date Hired:</el-col>
      <el-col :lg="20" :md="18" :xs="12" :sm="16">{{information.profile.datehired}}</el-col>
    </el-row>
    <el-row>
      <el-col :lg="4" :md="6" :xs="12" :sm="8">Date Regular:</el-col>
      <el-col :lg="20" :md="18" :xs="12" :sm="16">{{information.profile.dateregular}}</el-col>
    </el-row>
    <el-row>
      <el-col :lg="4" :md="6" :xs="12" :sm="8">Payee Code:</el-col>
      <el-col :lg="20" :md="18" :xs="12" :sm="16">{{information.profile.paye_code}}</el-col>
    </el-row>
    <el-row>
      <el-col :lg="6" :md="6" :xs="12" :sm="8">Cocolife Member ID:</el-col>
      <el-col :lg="20" :md="18" :xs="12" :sm="16">{{information.profile.medicard_code}}</el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="24">
        <h3>Personal Information</h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="12" :sm="24">
        <el-row>
          <el-col :xs="12" :sm="8">Last Name:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.lastname}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">First Name:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.firstname}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Middle Name:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.middlename}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Maiden Name:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.maiden_lastname}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Nick Name</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.nickname}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Birthdate:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.bday}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Age:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.age}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Birthplace:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.birthplace}}</el-col>
        </el-row>
      </el-col>
      <el-col :md="12" :sm="24">
        <el-row>
          <el-col :xs="12" :sm="8">Philhealth ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.philhealth_id}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">SSS ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.sss_id}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">GSIS ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.gsis_id}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">TIN ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.tin_id}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Pagibig ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.pagibig_id}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Unified ID:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.unifiedcrn}}</el-col>
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="8">Driver's License:</el-col>
          <el-col :xs="12" :sm="16">{{information.profile.driverlicense}}</el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="24">
        <h3>Address</h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-row v-for="(address, index) in information.address" v-bind:key="index">
          <el-col :md="4" :sm="8" :xs="12">{{address.address_type_name}}</el-col>
          <el-col :md="20" :sm="16" :xs="12">{{getFullAddress(address)}}</el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="24">
        <h3>Superiors</h3>
      </el-col>
    </el-row>
    <div v-for="(superior, index) in information.superiors" v-bind:key="index">
      <el-row>
        <el-col>{{index + 1}}. {{superior.boss_em_id_name}}, <b>{{superior.role_bg4_id_name}}</b></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Information',
  props: ['information'],
  methods: {
    getFullAddress (address) {
      const number = address.address ? `${address.address}` : ''
      const street = address.street ? `${address.street},` : ''
      const barangay = address.brgy ? `${address.brgy},` : ''
      const city = address.city_name ? `${address.city_name},` : ''
      const province = address.province_name ? `${address.province_name},` : ''
      const region = address.region_name ? `${address.region_name},` : ''
      const zip = address.zipcode ? `${address.zipcode}` : ''

      return `${number} ${street} ${barangay} ${city} ${province} ${region} ${zip}`
    }
  }
}
</script>

<style lang="scss">
  ol {
    margin: 0;
  }
</style>
